module.exports = {
  // App Info
  appstore_link         : "https://apps.apple.com/gb/app/id1597807943",                             
  playstore_link        : "https://play.google.com/store/apps/details?id=app.scanfood.tfm.scanfood",                                  // Enter Google Play Store URL.
  google_analytics_ID   : "G-F8FV49N1C5",                                        // Enter Google Analytics ID or ""
  presskit_download_link: null,                                
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://scanfood.app",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Food Barcode Scanner",
  app_price             : "Free",
  app_description       : "Scan barcodes and get nutritional information!",
  app_keywords          : ["barcodes", "food", "nutritional", "nutriscore", "nova", "ecoscore"],

  // Personal Info
  your_name              : "Sergio Durban",
  your_link              : null,
  your_city              : null,
  email_address          : "info@scanfood.app",
  linkedin_username      : null,
  facebook_username      : null,
  instagram_username     : null,
  twitter_username       : null,
  github_username        : "sdurban",
  youtube_username       : null,

  // Features List
  features               : [
  ],

  header_background             : "rgba(0, 0, 0, 0.5)",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  device_color                  : "black",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "ffffff",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#1d63ea",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
